import React from "react";
import { Label, TimePicker } from "@src/common/components";
import { Controller } from "react-hook-form";
import moment from "moment";

type Props = {
  stopIndex?: number;
  label: string;
  name: string;
  control: any;
  formatTime: any;
  watchValue: boolean;
  className?: string;
};

function TimeWindowController({
  stopIndex,
  label,
  name,
  control,
  formatTime,
  watchValue,
  className = "",
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <div className={`${className}`}>
          <Label className="block text-xs font-medium text-gray-700 truncate">
            {label}
          </Label>
          <TimePicker
            nullable
            size="mini"
            value={watchValue ? formatTime(field.value) : ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === "") {
                field.onChange(null);
              } else {
                const timeMoment = moment(
                  moment().format("YYYY-MM-DD") + "T" + event.target.value
                );
                const timestamp = timeMoment.unix();
                field.onChange(timestamp);
              }
            }}
            error={fieldState.error ? true : false}
          />
        </div>
      )}
    />
  );
}

export default TimeWindowController;
