import { Driver, TaskStatus } from "@api/graphql/generated/generated-types";
import { DriverAvatar } from "../Avatar";
import StatusDisplay from "@src/common/components/StatusDisplay";

type DriverNameCellProps = {
  name: string;
  avatarUrl: string | undefined | null;
  isAvailable?: boolean;
  row?: any;
  driver?: Driver | undefined;
  showStatus?: boolean;
};

export function DriverNameCell({
  name,
  avatarUrl,
  isAvailable,
  row,
  driver,
  showStatus = true,
}: DriverNameCellProps) {
  const pendingTasks =
    driver?.tasks.filter((task) => task.status !== TaskStatus.Completed) ?? [];
  return (
    <div className="flex items-center gap-2">
      <DriverAvatar
        driverPhoto={avatarUrl}
        driverName={name}
        size={35}
        isActive={isAvailable}
      />
      <div className="flex-1 overflow-auto">
        <div className=" text-sm font-bold truncate flex justify-between">
          {name}
        </div>
        {showStatus && (
          <div className="text-xs">
            <StatusDisplay value={pendingTasks.length} />
          </div>
        )}
      </div>
    </div>
  );
}
